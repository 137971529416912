import 'bootstrap/dist/css/bootstrap.min.css';
import './components/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'

ReactDOM.render(
	<App />, document.querySelector('#root')
);



