import React from 'react';
import Route from './Route';
import Menu from './Menu';
import Aboutme from './Aboutme';
import Projects from './Projects';
import Skills from './Skills';
import Contact from './Contact';


const App = () => {

	return (

		<div>
			<Menu />

			<Route path="/">
				<Aboutme />
			</Route>


			<Route path="/projects">
				<Projects />
			</Route>


			<Route path="/skills">
				<Skills />
			</Route>


			<Route path="/contact">
				<Contact />
			</Route>

		</div>
	)
};

export default App;

