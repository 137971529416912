import React, { useState } from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";


const Contact = () => {

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [confirm, setConfirm] = useState(false);
	const [validated, setValidated] = useState(false);

	//Handle the input change
	const sendRequest = () => {

		const data = {
			name: name,
			email: email,
			desc: message
		};

		axios.post(' https://pvv4p4481j.execute-api.us-east-1.amazonaws.com/contactus/contactme', data, {
			headers: {
				"Content-Type": "application/json",
			}
		}).then(function (response) {
			if (response.status === 200) {
				setConfirm(true);
			}
		});
	}

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
		}

		setValidated(true);
		if ((name !== "" && email !== "" && message !== "") && email.includes("@")) {
			sendRequest();
		}
	};

	return (

		(confirm === true) ?
			<div className="myContainer">
				<div className="confirm">
					<div className="send-confirm-logo">
						<FontAwesomeIcon className="icon" icon={faPaperPlane} />
					</div>
					<div className="content-aboutMe">
						<p><span className="thanks">Thank you for getting in touch.</span></p>
						<p className="thanks-message">I appreciate you contacting me. I got your email and will reach back to you soon.</p>
						<p className="thanks-message">Have a great day!</p>
					</div>


				</div>
				<div className="footer">
					<small className="footer">&copy; Copyright <span id="year">{(new Date().getFullYear())}</span>, Chayanne Paulino</small>
				</div>


			</div>
			:
			<div>
				<div className="myContainer">
					<Form noValidate validated={validated} className="form-container" method="post">
						<h1>Contact Me</h1>

						<Form.Group controlId="Name">
							<Form.Label>Name</Form.Label>
							<Form.Control
								className="custome-inputField"
								type="text"
								value={name}
								required
								onChange={e => setName(e.target.value)}
							/>
							<Form.Control.Feedback type="invalid">
								Please enter your name.
						</Form.Control.Feedback>

						</Form.Group>

						<Form.Group controlId="Password">
							<Form.Label>Email address</Form.Label>
							<Form.Control
								className="custome-inputField"
								type="email"
								value={email}
								required
								onChange={e => setEmail(e.target.value)}
							/>
							<Form.Control.Feedback type="invalid">
								Insert your email address
								</Form.Control.Feedback>
						</Form.Group>

						<Form.Group controlId="Message">
							<Form.Label>Message</Form.Label>
							<Form.Control
								as="textarea"
								className="custome-inputField"
								rows={3}
								value={message}
								required
								onChange={e => setMessage(e.target.value)}
							/>
							<Form.Control.Feedback type="invalid">
								You need to enter a message
							</Form.Control.Feedback>
						</Form.Group>

							<Button className="send-btn" onClick={handleSubmit} >Send</Button>
					</Form>

				</div>

				<div className="footer">
					<small className="footer">&copy; Copyright <span id="year">{(new Date().getFullYear())}</span>, Chayanne Paulino</small>
				</div>

			</div>

	);
};


export default Contact;