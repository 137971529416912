import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import skills from '../skillsList';


const Skills = () => {
	// Loop through skills sections this come from the skillslist
	const frontEnd = skills[0].frontEndList.map((ele) =>
		<p key={ele}>-<span className="item">{ele}</span></p>
	);

	const backEnd = skills[1].BackEndList.map((ele) =>
		<p key={ele}>-<span className="item">{ele}</span></p>
	);

	const other = skills[2].OtherList.map((ele) =>
		<p key={ele}>-<span className="item">{ele}</span></p>
	);

	const programingLanguage = skills[3].programmingLists.map((ele) =>
		<p key={ele}>-<span className="item">{ele}</span></p>
	);

	return (
		<div>
			<Container>
				<div className="skills-container">
					<h1>Skills</h1>
					<Row>
						<Col sm={3}><h2 className="skills-title">Front-End</h2>
							<div className="front-end">
								{frontEnd}
							</div>
						</Col>

						<Col sm={3}><h2 className="skills-title">Back-End</h2>
							<div className="back-end">
								{backEnd}
							</div>
						</Col>

						<Col sm={3}><h2 className="skills-title">Other</h2>
							<div className="back-end">
								{other}
							</div>
						</Col>

						<Col sm={3}><h2 className="skills-title">Programming Language</h2>
							<div className="programming-language">
								{programingLanguage}
							</div>
						</Col>
					</Row>
				</div>
			</Container>
		</div>
	);
};


export default Skills;