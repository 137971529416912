import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faHackerrank, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
//import Link from './Link';
import logo from '../images/my-pic1.jpg';

const Aboutme = () => {
	return (
		<div>
			<div className="aboutme-myContainer">
				<Container className="container-content">

					<Row className="logo">
						<Image
							className="logo text-center"
							src={logo}
							thumbnail />
					</Row>

					<Row className="intro">
						<div className="intro-mySelf">
							<h4 className="font-s-w">Hi,</h4>
							<h4 className="font-s-w">I'm <span className="name">Chayanne</span>,</h4>
							<h4 className="font-s-w">Software Developer</h4>
						</div>

					</Row>

					<Row className="content">

						<div className="content-aboutMe">
							<p>
								I am a software developer based in New York, who likes to learn and implement skills
								that can bring a simple solutions to complex problems. For 3+ years
								I have been pursuing a career as a Full-Stack Web developer. I am currently growing
								technical skills for Back-End development in Java.
							</p>
						</div>
					</Row>

					<Row>
						<div className="social-m">

							<a href="https://github.com/Chayanne90" target="_blanck">
								<FontAwesomeIcon className="icon" icon={faGithub} />
							</a>

							<a href="https://www.hackerrank.com/chayanne" target="_blanck">
								<FontAwesomeIcon className="icon" icon={faHackerrank} />
							</a>

							<a href="https://www.linkedin.com/in/chayanne-paulino/" target="_blanck">
								<FontAwesomeIcon className="icon" icon={faLinkedinIn} />
							</a>

							<a href="https://www.instagram.com/chaya.pau/" target="_blanck">
								<FontAwesomeIcon className="icon" icon={faInstagram} />
							</a>

						</div>
					</Row>

					{/* <Row className="resume">
						<Link
							href="https://cp-files-portfolio.s3.amazonaws.com/resume/ChayannePaulino_Resume.pdf"
							title="Resume" >
						</Link>
					</Row> */}

				</Container>

			</div>
		</div>
	);
};

export default Aboutme;