import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Spinner from 'react-bootstrap/Spinner'
import projectLogo0 from '../images/project-0.jpg';
import projectLogo1 from '../images/project-1.jpg';
import projectLogo2 from '../images/project-2.JPG';
import Link from './Link';

const Projects = () => {
	return (
		<div>
			<div className="projects-container">

				<h1>Projects</h1>

				<Container>


					<Row>
						<Col className="mycol" sm={4}>
							<Image src={projectLogo0} fluid />
							<div className="about-project">
								<label>Password Manager</label>

								<p>The Password Manager is
								part of my early projects,
								I challenged my self to
								design and code with
								Object Oritend programming
								using Java, as well as to
								reinforce database design.</p>
								<Link
									className="linkBox"
									href="https://github.com/Chayanne90/Password-Manager"
									title="GitHub">
								</Link>

							</div>

						</Col>
						<Col className="mycol" sm={4} >
							<Image src={projectLogo1} fluid />
							<div className="about-project">
								<label> Unix/Linux shell </label>
								<p>Personal Unix/Linux developed
								in Java. No GUI was implemented,
								for this project you can run
								on the IntelliJ or your favorite
								IDE console.</p>

								<Link
									className="linkBox"
									href="https://github.com/Chayanne90/MyShell"
									title="GitHub">
								</Link>

							</div>
						</Col>
						<Col className="mycol" sm={4}>
							<Image src={projectLogo2} fluid />
							<div className="about-project">
								<label>YouTube Search</label>

								<p> YouTube Search was developed
								for the purpose of practice my skills
								with React JS, where I used Class based Components
								along with Youtube API. This
								same project was later I refactored
								to use Hooks (useSate, useEffect and useRef).
								</p>
								<Link
									className="linkBox"
									href="https://github.com/Chayanne90/Youtube-BrowserApp"
									title="GitHub"></Link>
							</div>

						</Col>
					</Row>
				</Container>

			</div>

			<div className="projects-container">
				<div className="in-progress-content">
					<label className="new-projects-label">New projects in progress</label>
					<Spinner animation="border" className="mySpinner" variant="primary" />
				</div>

			</div>
		</div >
	);
};

export default Projects;