import React from 'react';
import Nav from 'react-bootstrap/Nav';



const NavLink = ({ href, children }) => {

	const onClick = (e) => {

		if (e.metaKey || e.ctrlKey) {
			return;
		}

		e.preventDefault();
		window.history.pushState({}, '', href);


		const navEvent = new PopStateEvent('popstate');
		window.dispatchEvent(navEvent);

	};

	return (
		<Nav.Link onClick={onClick} href={href} >{children}</Nav.Link>
	)

};




export default NavLink;