import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavLink from './NavLink';


const Menu = () => {
	return (
		<div>

			<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
				<Navbar.Brand href="#home">Chayanne Paulino</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">

					<Nav >
						<NavLink href="/">About Me</NavLink>
						<NavLink href="/projects">Projects</NavLink>
						<NavLink href="/skills">Skills</NavLink>
						<NavLink href="/contact">Contact</NavLink>
					</Nav>
				</Navbar.Collapse>
			</Navbar>

		</div>
	);
};

export default Menu;