// list of styles
const skills = [
	{
		label: "Front-End",
		frontEndList: ["JavaScript", " React JS", "HTML5", "CSS3", "Bootstrap 5"]
	},
	{
		label: "Back-End",
		BackEndList: ["Node JS", "MySQL", "MongoDB", "Microsoft SQL Server", "NoSQLBootster"]
	},
	{
		label: "Other",
		OtherList: ["Data Structure", "Bash", "Git", "CI/CD with AWS"]
	},
	{
		label: "Programming Language",
		programmingLists: ["Java"]

	},
	{
		label: "Certifications",
		certificationList: ["AWS Cloud Practitioner"]
	}
]

export default skills;


