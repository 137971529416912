import React from 'react';

const Link = ({ href, title }) => {

	const styles = {
		link: {
			":hover": {
				textDecoration: "none",
				color: "#fff",
			},
		},
	}

	return (
		<a style={styles.link} className="linkBox" href={href} target="_blank" rel="noopener noreferrer">{title}</a>
	);
};

export default Link;